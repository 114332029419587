.header {
  width: 100%;
  padding: 2em;
  background-color: #181818;
  color: #ebebeb;
  font-weight: bold;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 2em;
  align-items: center;

  a {
    color: #ebebeb;
  }
}

.logo img {
  width: 100%;
  height: auto;
}
