.resource {
  display: flex;
  flex-direction: column;
  background-color: #181818;
  color: #ebebeb;
  border-radius: 8px;
  padding: 1.5em;
  gap: 1em;

  h1 {
    display: inline-flex;
    align-items: center;
  }
}

.details {
  grid-area: details;
  display: grid;
  grid-template-columns: auto 80px;
  grid-auto-rows: 1fr;
  gap: 2em;

  a {
    color: #ebebeb;
  }

  h1,
  p {
    margin: 0;
  }
}

.personal {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  button {
    font-size: large;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    gap: 0.5em;
    align-items: center;
    background-color: transparent;
    color: #ffffff;
    text-align: center;
    border: 0;
    border-radius: 4px;

    svg {
      transition: all 1s;
      color: #ebebeb;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
