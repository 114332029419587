:root {
  background-color: #ebebeb;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
  padding-bottom: 2em;
  gap: 2em;
}

.resources {
  width: 100%;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 1.5em;
}

a {
  display: inline-block;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
